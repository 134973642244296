// import tableUtil from '@/components/global/TableModal/util' //组件
import { getProductList } from "../components/api";
import Step from "@/components/global/Step/Step.vue";
import ProductListNew from "../components/ProductListNew.vue";
import ProductModal from "../components/ProductModal.vue";
import axios from "axios";
import { dmsPublicPath } from "@/common/constant.js";
import {
  // 分销商 分销地址
  showAnother,
  // 收货地址切换带出联系人
  // 获取城市
  cityChange,
  // 获取城镇 区
  townChange,
  // 获取城镇区下一级
  getQu,
  // 分销商切换 获取新分销商地址
  getDISAdd,
  // 分销商地址切换请求联系人
  getPhone,
  // dms配送方式
  getDeliveryMode,
  // 查询商品行信息
  // getCustomer,
  findBaseData,
  // 特惠专区接口
  // specialZone,
  getBasicMessage, //零售订单基础信息
  // 零售订单下单
  retailOrder,
  // 获取销售组织list
  getOrgList,
  getProvice,
  findMaterialByStore,
  getSeverList,
  getWarehouseList,
  uploadAttachment,
  getDeliveryType, findSalesOrderDetail
} from "./api";
import moment from "moment";
import { mapState } from "vuex";
import { isEnableOrNotBucha } from "@/views/stockCenter/adjustmentApply/status/api";
import { getAdressapi } from "@/views/settlement/api";
import addUserSource from "@/views/userOperations/IntendedUserEntry/components/addUserSource";
import addUserTips from "@/views/userOperations/IntendedUserEntry/components/addUserTips";
import { findList, findListLabel, saveInfo, saveInfoLabel } from "@/views/userOperations/IntendedUserEntry/api";
import { getDeliveryAmountFind, getTOF } from "@/views/stockCenter/adjustmentApply/allot/api";
import { getUserList } from "@/views/userOperations/IntentionList/api";
import { sysconfig } from "@/views/order/channelExamine/api";
import { ctsApi, fxZsyhGetDeliveryMethod, isBrightPilotRegion } from "@/views/channel/api";
import { findMoneyBuyBegin } from "@/views/specialZone/api";
import { checkRetailL, isSxProduct } from "@/views/channelInput/saleNew/api";

export default {
  name: "Home",
  components: {
    Step,
    ProductListNew,
    ProductModal,
    addUserSource,
    addUserTips
  },
  // props: {
  //   orderType: {
  //     type: String,
  //     required: true
  //   }
  // },
  data() {
    return {
      sexoptions:[
        {
          id:1,
          name:'男士'
        },
        {
          id:2,
          name:'女士'
        }
      ],
      rowSelectionType: "checkbox", //是否编辑
      selRow: {}, //当前编辑的行
      modelShow: false,
      bankChecked: true,
      visible: false,
      steps: ["录入订单", "确认订单信息", "提交订单"],
      currentStep: 2,
      deliveryOptions: [],
      deliveryOptionsOld:[],
      prods: [],
      // 开票户头账号
      choseAccounts: [],
      // 开票户头id
      invoiceId: "",
      //是否允许提前发货
      allowAdvance: true,
      // 用户选择截止时间
      // endTime: '',
      // 经销商下拉选择框
      distr: false,
      // 经销商地址
      distrAddress: "",
      distrAdd: false,
      distrName: "",
      // 经销商地址
      orderDis: [],
      // 选择直配到分销时填写客户单号
      orderNumber: "",
      // 用户详细地址
      userDetailAddress: "",

      // 省市区三级联动
      secondCity: "",
      // 接收子组件选中的商品
      sonChectItme: [],
      receiveAdd: false,
      // 收货地址/统仓地址
      receive: "",
      // 销售组织id
      receiveID: "",
      // 收货地址类型 obj.shareFlag=='Y'?'统仓地址':'收货地址'
      // 配送方式ID
      sendId: "",
      // 分销商地址选择
      distributionAddress: [],
      // 输入输入内容
      searchInt: "",
      // 省
      provices: [],
      //市
      getCity: [],
      // 城镇
      getTown: [],
      // 城镇下一级
      qu: [],
      // 省市区选择
      proObj: {},

      // 经销商地址列表
      choseDistribution: [],
      // 分销商列表
      DisList: [],
      // 经销商及经销商地址
      distributionAdd: {
        DISCODE: "",
        DISADD: ""
      },
      // 经销商切换获取地址 接口参数
      DISADDPARAMS: {
        customerId: "",
        orgId: "",
        matklId: ""
      },
      // 要求到货周
      arriveOptions: [],
      selected: "",
      // 商品数量、体积、价格、预付款
      goodsNum: 0,
      goodsVolume: 0,
      goodsPrice: 0,
      advancePrice: 0,
      TotalPayment: {},

      //返利 （返利选项是否选中）
      //返利按钮是否选中
      isTradeChecked: true,
      // 预付比例
      advancePayRate: 0,
      totalAdvanceMoney: 0,
      drawerName: "",
      dissize: false,
      // 点击提交按钮提交的参数
      orgAndGroup: "",
      orderCartIds: {},
      // 配送方式
      trans: "",
      hisenseOff: true,
      officehis: [],
      officeCode: "",
      receivehis: "",
      officeHis: false,
      orgCode: "",
      matklId: "",
      orgId: "",
      orgtitle: "",
      obj: {},
      // 提交给后台订单详情
      submitGoods: [],
      endTime: "",
      // 有效截止时间
      startTime: "",
      // 后台接口最大截止时间
      maxEndDate: "",
      // 面包屑
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        // {
        //   path: "/cart",
        //   name: "cart",
        //   title: "订单录入"
        // },
        {
          path: "/",
          name: "",
          title: "零售订单编辑审核"
        }
      ],
      // 按钮组件loading
      isLoading: false,
      // 页面加载loading
      pageLoadFlag: false,
      //备注
      BZvalue: "",
      // 收货地址
      shippingAddress: {},
      totalOrderedQty: 0,
      totalAmount: 0,
      purchaseOrderItem: [],

      supplierCode: "",
      // 渠道客户
      business: {
        code: "",
        name: ""
      },
      // 基础信息接口数据
      realObj: {},
      // 销售门店
      XSMD: [],
      receiveAddXS: false,
      receiveXS: "",
      receiveXSID: "",
      // 发货仓库
      receiveFH: [],
      receiveAddFH: false,
      receiveFHS: "",
      receiveFHID: "",
      // 收货地址
      receivehisSH: "",
      // 销售类型
      saleOptions: [
        {
          name: "零售",
          id: "retail"
        },
        {
          name: "工程",
          id: "engineering"
        }
      ],
      XSLX: "retail",
      kpf: "",
      productList: [],
      dataCache: [],
      //组件
      columns: [
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          width: "120",
          key: "model"
        },
        {
          label: "颜色",
          prop: "colour",
          width: "80",
          key: "colour"
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          width: "100",
          ellipsis: true,
          key: "materialGroupName"
        },
        {
          label: "价格(元)",
          prop: "999",
          width: "100",
          key: "999"
        },
        {
          label: "体积(m³)",
          prop: "volume",
          width: "110",
          key: "volume"
        },
        {
          label: "仓库",
          prop: "warehouseName",
          width: "124",
          ellipsis: true,
          key: "warehouseName"
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          width: "110",
          key: "invStatusName"
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          width: "110",
          key: "invStatusTypeName"
        },
        {
          label: "可用库存",
          prop: "bigQty",
          width: "110",
          key: "bigQty"
        },
        {
          label: "销售数量",
          prop: "",
          key: "borderedQty",
          minWidth: 110,
          slot: "count"
        }
      ],
      dataList: [],
      isVisible: false,
      tabelIsLoading: false,
      searchIpt: "",
      tableTotal: 0,
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      // 城市
      checkCity: "",
      checkTownCode: "",
      checkXZ: "",
      sheng: "",
      materialGroup: [],
      xszhId: "",
      fhckId: "",
      fhckType: "",//仓库类型
      xsmdId: "",
      isEnableOrNot: true,
      TGOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      tgType: "",
      SZOptions: [
        {
          name: "是",
          id: "1"
        },
        {
          name: "否",
          id: "0"
        }
      ],
      szytType: "",
      fwlbId: [],
      // 选中的服务列表
      // serverBox:'',
      // 服务列表数据
      FWlist: [],
      fileList: [],
      ImgIds: [],// 上传后的id
      visibleshop: false,
      visibleshopGC: false,
      visibleshopLS: false,
      show1: false,
      show2: false,
      show3: false,
      show1Message: "",
      show2Message: "",
      show3Message: "",
      isOldNew: 0, // 是否依旧换新 0否 1是
      isCompleteOld: 0, // 是否完成收旧 0否 1是
      oldMachCategorys: [],// 旧机品类列表
      oldMachCategory: "",// 旧机品类
      oldMachCategoryName: "",
      oldMachTreatWays: [], // 旧机处理途径
      oldMachTreatWay: "",// 旧机处理途径
      oldMachTreatWayName: "",
      companyList: [],
      fetching: false,
      provinceId: "",
      cityId: "",
      districtId: "",
      adressObj: {},
      selectDisabled: false,
      sexId:1,// 性别
      userSorceList:[],
      visibleSorce:false,
      SorceIds:'',
      userTipsList:[],
      TipsIds:[],
      visibleTips:false,
      mdlx:'',
      addressHx:[],
      cherkStatus:false,
      visibleAAA:false,
      openCloseBtn:false,
      showTIP:false,
      msgStatus:'',
      smallLoanSwitch: false,
      showInfo:'',
      searchStr:'',
      proIndex:'',
      documentNum:'',
      recoveryModeShow: false,
      recoveryTxt:'',
      recoveryShowTxt:'',
      productListOld:0,
      isShowLogisticsIcon:false,// 是否展示问号表示
      serviceCharge:0,
      orderServiceFeeItemDtoList:[],
      warehouseInfoId:'',
      phone:'',
      text1:'',
      text2:'',
      haveDeliveryFee:false,
      transDefault:'',
      compassHouseTaskId:'',
      compassHouseTaskName:'',
      startShowTime:'',
      endOpen:false,
      isBright:false,// 判断是试点区域
      sXFlag:null,
      sXMessage:'',
      allTC:false,
      isNoSxProduct:null,
      orderDetail: {}
    };
  },
  computed: {
    ...mapState({
      isFenxiaoOrder: state => state.user.userInfo.account.marketModels,
      toPath: state => state.user.toPath
    })
  },


  mounted() {
    //获取基本信息
    this.basicMessage();

    this.getLoanSwitch()
    // this.getuserSorceList()
    this.getuserTipList()
    this.getisKQ()
    // this.getDelivery();
    // 省市区
    this.carList();
    this.getywy();
    // 开票方
    this.getkpf();

    var now = new Date();
    var year = now.getFullYear(); //得到年份
    var month = now.getMonth() + 1; //得到月份
    var date = now.getDate(); //得到日期
    // var day = now.getDay(); //得到周几
    var hour = now.getHours(); //得到小时
    var minu = now.getMinutes(); //得到分钟
    var sec = now.getSeconds(); //得到秒
    var time =
      year + "-" + month + "-" + date + " " + hour + ":" + minu + ":" + sec;
    var time2 = year + "-" + month + "-" + date + " " + hour + ":" + "00";
    this.startTime = time2;
    // 提示文字修改
    this.getMessage();
    this.getMessage1();
    this.getMessage2();
    this.getOldMachCategory();
    this.getOldMachTreatWays();
    const data1 = this.$getAlertInfoByCode('TS0248')
    const data2 = this.$getAlertInfoByCode('TS0249')
    this.text1 = data1.msgDesc.split('${phone}')
    this.text2 = data2.msgDesc
    this.phone = data1.remark

  },
  watch: {
    productList: {
      handler() {
        this.resetTrans()
        let YCnum = 0
        if (this.productList.length > 0 && this.showTIP && this.smallLoanSwitch) {
          for(let i = 0; i < this.productList.length;i++) {
            if(this.productList[i] && this.productList[i].gicWarehouseType && this.productList[i].gicWarehouseType == 20) {
              YCnum+=1
            }
          }
          this.selectDisabled = true;
        } else {
          this.selectDisabled = false;
        }
        // 如果选择了统仓的 要展示并且默认
        if(YCnum>0 && this.showTIP && this.smallLoanSwitch) {
          this.openCloseBtn = true
          this.cherkStatus = true
        } else {
          this.openCloseBtn = false
          this.cherkStatus = false
        }

        if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
          this.getFWF()
        }

        let tcNum = this.productList.filter(obj => obj.gicWarehouseType == 20).length;
        this.allTC = tcNum==this.productList.length
        this.isNoSxProduct = this.productList.every(el => el.materialGroupCode != '1100104' && el.materialGroupCode != '1100107') // 是否全不是商显产品
        if (this.allTC && this.proObj.zCode && !this.isNoSxProduct) {
          this.getIsSxProduct()
        }
      },
      immediate: true,
      deep: true,
    },
    // receiveID: {
    //   immediate: true,
    //   handler(newValue) {
    //     this.isEnableOrNotBuchaUpdate();
    //   }
    // }
    trans: {
      immediate: true,
      handler(newValue) {


      }
    },
    XSLX: {
      immediate: true,
      handler(newValue) {

      }
    },
    fhckId: {
      immediate: true,
      handler(newValue) {

      }
    }

  },
  methods: {
    handleGoProduct(){
      const params = {
        storeId: this.XSMD.find(item => item.value == this.xsmdId).key,
        storeName: this.xsmdId,
        userName: this.business.name,
        phone: this.business.code,
        gender: this.sexId,
        SorceIds: 5007687,
        userLabel: this.userTipsList.filter(item => item.checked).map(item => item.id),
        userDetailAddress: this.userDetailAddress,
        addressHx: this.addressHx,
        proviceCode: this.proObj.proviceCode,
        cityCode: this.proObj.cityCode,
        townCode: this.proObj.townCode,
        zCode: this.proObj.zCode,
      }
      const { id, agentDealers } = this.orderDetail;
      localStorage.setItem("goProduct", JSON.stringify(params));
      this.$router.push({
        path: "/confirm/channel",
        query: {
          type:'orderList',
          productId: this.productList[0].productCode,
          qty: this.productList[0].borderedQty,
          orgId: agentDealers[0].orgId,
          policyId: '',
          agentDealers:JSON.stringify(agentDealers),
          versions: '',
          supplierName: agentDealers.length==1?`${agentDealers[0].orgName}-${agentDealers[0].dealerName}`:'',
          supplierId: agentDealers.length==1?agentDealers[0].orgId:'',
          pageUrl: 'detailPro',
          saleOrderId: id,
        }
      });
    },
    // 重置配送方式
    resetTrans(){
      let proNum =  this.productList.reduce((el,curr) => el + Number(curr.borderedQty), 0);
      if(proNum<6) {
        let newArr = this.deliveryOptionsOld.filter(it=>it.code!=='07')
        this.deliveryOptions =  newArr
        if(this.trans == '07'){
          this.transDefault = this.deliveryOptions.findIndex(el => el.check == true)>-1?this.deliveryOptions.findIndex(el => el.check == true):''
          // 赋值配送方式
          this.trans = this.deliveryOptions[this.transDefault].code
          this.haveDeliveryFee = this.deliveryOptions[this.transDefault].haveDeliveryFee == 1? true : false
          this.isShowLogisticsIcon = this.deliveryOptionsOld.some(el => ['04', '08'].includes(el.code))
        }
        this.$forceUpdate()
      }else {
        this.deliveryOptions = this.deliveryOptionsOld
      }
    },
    getDetails(){
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        salesOrderId:this.$route.query.id
      }
      this.pageLoadFlag = true
      findSalesOrderDetail(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code == 0) {
          this.documentNum = data.data.documentNum
          this.xsmdId = data.data.storeName
          this.receiveXSID = String( data.data.storeCode)
          this.getMetarial()
          if (this.XSMD.length > 0) {
            let obj = this.XSMD.find((i) => {
              return i.key === this.receiveXSID;//筛选出匹配数据
            });
            this.mdlx = obj.isSpeclalShop
            this.shopId = obj.key
          }
          this.business.code = data.data.phone
          this.business.name = data.data.customerName
          this.trans = data.data.deliveryMode
          this.startTime = data.data.edt
          this.BZvalue = data.data.message
          this.fileList = data.data.attachmentList
          this.isOldNew = data.data.isOldNew
          Object.assign(this.orderDetail, data.data);
          if( this.isOldNew == 1){
            this.isCompleteOld =  data.data.isCompleteOld
            // this.oldMachCategory =  data.data.isCompleteOld
            this.oldMachTreatWay =  data.data.oldMachTreatWay
          }
          this.productList = data.data.salesOrderItem
          this.productListOld = data.data.salesOrderItem
          this.productList.forEach(item=>{
            item.brandName = item.brand
            item.bprice = ''
            item.warehouseName = item.gicWarehouse
            item.checked = true
            item.isShowBt = item.couponAmount>0?true:false
            item.bt = item.couponAmount?item.couponAmount:0

          })

          this.proObj.proviceCode = data.data.provinceId
          this.proObj.cityCode =  data.data.cityId
          this.proObj.townCode =  data.data.countyId
          this.proObj.zCode =  data.data.townId
          this.addressHx = [data.data.provinceId,data.data.cityId,data.data.countyId,data.data.townId]
          this.userDetailAddress = data.data.addressDesc
          this.sexId = data.data.customerGender
          if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
            this.getFWF()
          }
          this.getFxGetDeliveryMethod()
          // 如果后端返回回收方式 就展示
          this.recoveryTxt = data.data.recycleWay
          this.recoveryShowTxt= data.data.recycleWay==1?'门店回收':'有闲有品'
          if(this.recoveryTxt) {
            this.recoveryModeShow = true
            this.isOldNew = '1'
            this.isCompleteOld = '1'
            this.oldMachCategory =  data.data.oldMachCategory
            this.oldMachCategoryName =  data.data.oldMachCategoryName
          } else {
            this.isOldNew = '0'
            this.isCompleteOld = '0'
          }
          this.orderLabels = data.data.orderLabels
          if( this.business.code  && this.shopId){
            this.changePhoneNum()
          } else {
            // 如果当前来源是用户录入 就默认给赋值旧机换新
            if(this.orderLabels == '用户录入'){
              this.userTipsList.forEach(item => {
                if(item.id == 5007820){
                  item.checked = true;
                  this.TipsIds.push(item.id )
                }
              });
            }
          }

          this.pageLoadFlag = false
        } else {
          // this.$message.warning('获取详情失败')
          this.pageLoadFlag = false
        }
      }).catch(error=>{
        this.pageLoadFlag = false
      })
    },
    // 获取小贷总开关
    getLoanSwitch() {
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.smallLoanSwitch = res.data.data == 'Y' ? true : false
      })
    },
    changePhoneNum() {
      if(this.userTipsList.length>0){
        this.userTipsList.forEach(item=>{
          item.checked = false
          this.TipsIds=[]
        })
      }
      this.business.code =  this.business.code.replace(/\D/g, '')
      if(this.business.code.trim().length == 11){
        let data = {
          phone: this.business.code,
          shopInfoId:this.shopId
        }
        getUserList(data).then(res=>{
          if(res.data.code == 0 && res.data.list && res.data.list.length > 0) {
            if(!this.business.name){
              this.business.name =  res.data.list[0].userName
            }
            this.SorceIds =  res.data.list[0].sourceId
            this.compassHouseTaskId = res.data.list[0].compassHouseTaskId?res.data.list[0].compassHouseTaskId:''
            this.compassHouseTaskName = res.data.list[0].compassHouseTaskName?res.data.list[0].compassHouseTaskName:''
            this.sexId = res.data.list[0].genderName=='男' ? 1 : (res.data.list[0].genderName=='女'? 2 : 1 )

            // this.proObj.proviceCode = res.data.list[0].provinceCode
            // this.proObj.cityCode =  res.data.list[0].cityCode,
            // this.proObj.townCode =  res.data.list[0].districtCode,
            // this.proObj.zCode =  res.data.list[0].townCode
            // this.userDetailAddress =  res.data.list[0].address
            if( res.data.list[0].userLableList.length>0 && this.userTipsList.length > 0){
              this.userTipsList.forEach(item=>{
                res.data.list[0].userLableList.forEach(twoItem=>{
                  if(item.id == twoItem.labelId) {
                    item.checked = true
                    this.TipsIds.push(twoItem.labelId)
                  }
                })
              })
            }else {
              this.sexId = 1
              this.TipsIds=[]
              this.TipsNames=[]
              this.$nextTick(()=>{
                if(this.userTipsList.length>0){
                  this.userTipsList.forEach(item=>{
                    item.checked = false
                    if(item.id  == 5007817){
                      item.checked = true
                      this.TipsIds.push(item.id)
                      this.TipsNames.push(item.label);
                    }
                  })
                }
              })
            }

          }else {
            this.SorceIds = 1
            // this.proObj.proviceCode = ''
            // this.proObj.cityCode = ''
            // this.proObj.townCode = ''
            // this.proObj.zCode = ''
            // this.userDetailAddress = ''
            this.sexId = 1
            this.compassHouseTaskId = ''
            this.compassHouseTaskName = ''
            // this.addressHx = []
            // 如果当前来源是用户录入 就默认给赋值旧机换新
            // if(this.orderLabels == '用户录入'){
            //   this.userTipsList.forEach(item => {
            //     if(item.id == 5007820){
            //       item.checked = true;
            //       this.TipsIds.push(item.id )
            //     }
            //   });
            // }
            this.TipsIds=[]
            this.TipsNames=[]
            this.$nextTick(()=>{
              if(this.userTipsList.length>0){
                this.userTipsList.forEach(item=>{
                  item.checked = false
                  if(item.id  == 5007817){
                    item.checked = true
                    this.TipsIds.push(item.id)
                    this.TipsNames.push(item.label);
                  }
                })
              }
            })
          }
        })
      }

    },


    changeStatus(){
      this.cherkStatus = !this.cherkStatus
      this.$forceUpdate()
    },
    onAddressChange(addressValArr) {
      // 长度如果等于2 乡镇就置空
      if(addressValArr&&addressValArr.length == 2) {
        this.checkTownCode = '';
        this.proObj.townCode = ''
      }
      // 长度如果等于3 区县就置空
      if(addressValArr&&addressValArr.length == 3) {
        this.checkXZ = '';
        this.proObj.zCode = ''
      }
      if(addressValArr.length > 0) {
        addressValArr.forEach((item, index) => {
          if (index == 0) {
            this.sheng = item;
            this.proObj.proviceCode = item
          }
          if (index == 1) {
            this.checkCity = item;
            this.proObj.cityCode = item
          }
          if (index == 2) {
            this.checkTownCode = item;
            this.proObj.townCode = item
          }
          if (index == 3) {
            this.checkXZ = item;
            this.proObj.zCode = item
            if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
              this.getFWF()
            }
          }

        });
      } else {
        this.startTime = ''
        this.startShowTime = ''
        // this.proObj.proviceCode = ''
        // this.shengCode = ''
        // this.shiCode = ''
        // this.proObj.cityCode = ''
        // this.quCode = ''
        // this.proObj.townCode = ''
        // this.xianCode = ''
        // this.proObj.townCode = ''
      }
      if (this.allTC && this.proObj.zCode && !this.isNoSxProduct) {
        this.getIsSxProduct()
      }
      // ['340000000000', '340600000000', '340603000000', '340603400000', __ob__: Observer]
      // ..
    },
    // 获取用户标签
    getuserTipList() {
      this.pageLoadFlag = true
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findListLabel(data).then(res => {
        if(res.data.code == 0) {
          this.userTipsList = res.data.data;
          if( this.userTipsList.length > 0) {
            this.userTipsList.forEach(item => {
              item.checked = false;
            });
          }
          this.pageLoadFlag = false
        }
      });
    },
    // 获取用户来源
    getuserSorceList() {
      this.pageLoadFlag = true
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id
      };
      findList(data).then(res => {
        if(res.data.code == 0) {
          this.userSorceList = res.data.data;
        }
        this.pageLoadFlag = false

      });
    },
    getTotalProd() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.borderedQty || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalVol() {
      return this.productList.reduce((sum, item) => {
        if (item.checked) {
          return sum + (item.borderedQty || 0) * (item.volume || 0);
        } else {
          return sum;
        }
      }, 0);
    },
    getTotalMoney() {
      return this.productList.reduce((sum, item) => {
        if (item.checked && !isNaN(item.bprice)) {
          // return sum + (item.borderedQty || 0) * (item.bprice || 0);
          return sum + (((item.bprice-(item.bt||0)) * item.borderedQty >= 0?(item.bprice-(item.bt||0)) * item.borderedQty:0) || 0);

        } else {
          return sum;
        }
      }, 0);
    },
    // getxj(bprice, borderedQty, item) {
    //   item.amount = new Number((bprice || 0) * (borderedQty || 0)).toFixed(2);
    //   return item.amount;
    // },
    // 点击
    changeSorceBtn(item) {
      if(this.SorceIds == item.id) {
        this.SorceIds = ''
        return
      }
      this.SorceIds = item.id
      this.$forceUpdate()

    },

    changeTipsBtn(item) {
      if (!item.checked) {
        if (this.TipsIds.length >= 8) {
          this.$message.warning("用户标签选择不能超过8个");
          return;
        }
      }
      item.checked = !item.checked
      this.$forceUpdate()
      this.TipsIds = []
      if(this.userTipsList.length > 0){
        this.userTipsList.forEach(items=>{
          if(items.checked == true) {
            this.TipsIds.push(items.id)
          }
        })
      }

      this.$forceUpdate()
    },
    // 点击弹窗取消和确定的时候
    chengeOK(item) {
      this.TipsIds = [];
      this.TipsIds = item;
      this.userTipsList.forEach(items=>{
        items.checked = false
        item.forEach(itemB=>{
          if(items.id == itemB) {
            items.checked = true
          }
        })
      })
    },
    // 用户来源点击确定 调接口 增加数据 成功的话赋值
    getReason(value,value2) {
      if (!value) {
        this.SorceIds = value2
        return;
      }
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        source: value,
        remark: ""
      };
      this.pageLoadFlag = true;
      saveInfo(data).then(res => {
        if (res.data.code == 0) {
          this.userSorceList.push(
            {
              id: res.data.data,
              source: value
            }
          );
          this.SorceIds = "";
          this.SorceIds = this.userSorceList[this.userSorceList.length - 1].id;
          this.$forceUpdate();
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg);
          this.pageLoadFlag = false;
        }

      });
    },
    getReasonTip(value, value1) {
      this.pageLoadFlag = true;
      let data = {
        custInfoId: this.$store.state.user.userInfo.customer.id,
        type: 2,
        label: value,
        remark: value1
      };
      saveInfoLabel(data).then(res => {
        if (res.data.code == 0) {
          this.userTipsList.push(
            {
              id: res.data.data,
              label: value,
              checked: true
            }
          );
          this.TipsIds.push(res.data.data)
          this.pageLoadFlag = false;
        } else {
          this.$message.warning(res.data.msg)
          this.pageLoadFlag = false;
        }
      });
    },
    changeSEX(e) {
      this.sexId = e.target.value;
    },
    getAdress(value) {
      let id = null;
      if (this.proObj.townCode) {
        id = this.proObj.townCode;
      } else if (this.proObj.cityCode) {
        id = this.proObj.cityCode;
      } else if (this.proObj.proviceCode) {
        id = this.proObj.proviceCode;
      } else {
        id = 0;
      }

      this.userDetailAddress = value;
      let a = {
        searchstr: this.userDetailAddress,
        region: id
      };
      let b = {
        searchstr: this.userDetailAddress
      };
      let data = id !== 0 ? a : b;
      this.fetching = true;
      getAdressapi(data).then(res => {
        if (res.data.data.pois.length > 0 && res.data.data.pois !== null) {
          this.companyList = [...new Set(res.data.data.pois)]; //缓存列表数据，前端分页
        } else {
          this.companyList = [];
        }

        this.fetching = false;
      });

    },
    //查询数据
    searcha() {

    },
    async sumbitaaa() {
      this.isLoading = true;
      this.isLoading = false;
      this.panduanfreeTip();
      return;
    },
    // 提示信息 
    getMessage() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495767"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show1Message = msg && msg.msgDesc;

    },
    getMessage1() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495772"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show2Message = msg && msg.msgDesc;
    },
    getMessage2() {
      const msg = this.$configTotal({
        route: this.$route.fullPath,
        id: "14187495874"
      });
      // this.$message.info(msg && msg.msgDesc);
      this.show3Message = msg && msg.msgDesc;
    },
    //  判断商品是否为免标识
    panduanfreeTip() {
      if (this.XSLX == "retail" && (this.fhckType == 20 || this.fhckType == "20")) {
        this.visibleshopLS = true;
      } else if (this.XSLX == "engineering" && (this.fhckType == 20 || this.fhckType == "20")) {
        this.visibleshopGC = true;
      } else {
        this.submitOrder()
      }
    },
    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.pageLoadFlag = true
      if ((file.size) / 1024 > 20480) {
        this.$message.warning("附件大小不能大于20M");
        this.pageLoadFlag = false
        return false;
      } else {
        // 上传附件   调接口
        let account = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.account.account : JSON.parse(localStorage.getItem("userInfo")).account.account;
        let cisCode = this.$store.state.user.userInfo ? this.$store.state.user.userInfo.customer.cisCode : JSON.parse(localStorage.getItem("userInfo")).customer.cisCode;
        let formData = new FormData();
        formData.append("cisCode", cisCode);
        formData.append("businessType", "retailOrder");
        formData.append("file", file);
        const BASE_API = dmsPublicPath;
        let config = {
          headers: { "content-type": "multipart/form-data" }
        };
        axios.post(BASE_API + "/wechatEntrance/entrance.do" + "?account=" + account + "&method=uploadAttachment" + "&type=1", formData, config).then(res => {
          let resResult = JSON.parse(res.data);
          this.pageLoadFlag = false
          if (resResult.code == "0") {
            this.fileList = [...this.fileList, file];
            this.fileList.forEach(item => {
              item.size2 = ((item.size) / 1024).toFixed(2);
            });
            this.ImgIds.push(resResult.data[0].id);
          } else {
            this.$message.warning("上传失败", resResult.msg);
            this.pageLoadFlag = false
          }
        }).catch(error=>{
          this.pageLoadFlag = false
        });

        return false;
      }
      // this.fileList = [...this.fileList, file];
      // return false;
    },
    handleChange({ fileList }) {


      let fileList1 = [...this.fileList];
      this.fileList = fileList1;
      this.fileList.forEach(item => {
        item.size2 = ((item.size) / 1024).toFixed(2);

      });

    },

    getSeverInfo(orgCode, warehouseCode) {
      // if (this.fhckType != 20) {
      //   return
      // }
      this.pageLoadFlag = true;
      this.FWlist = [];
      this.fwlbId = [];
      // 根据销售组织和四级地址获取服务列表数据
      let data = {
        orgCode: orgCode,
        warehouseCode: warehouseCode,
        provinceCode: this.proObj.proviceCode,
        cityCode: this.proObj.cityCode,
        countyCode: this.proObj.townCode,
        townCode: this.proObj.zCode

      };
      getSeverList(data).then(res => {

        this.pageLoadFlag = false;
        // 获取服务列表数据
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // 目前接口还不通,需要修改赋值参数
        this.FWlist = data.data;

        // 默认选中
        if (this.FWlist && this.FWlist.length > 0) {
          for (const item of this.FWlist) {
            if (item.isDefault == 1 && item.isSupport == 1) {
              this.fwlbId.push(item.serviceCode);
            }
            ;
          }
          ;
        }

      }).catch(res => {

        this.pageLoadFlag = false;
      });
    },
    // handleChangeAddressFW(value, e) {
    //   // 获取选中的服务列表的id
    //   this.serverBox = (e.map(item=>item.key)).toString()
    // },
    // 送装一体修改选项
    changeSZtype(e) {
      this.szytType = this.SZOptions[e.target.value].id;
    },
    // isEnableOrNotBuchaUpdate() {
    //   if (!this.receiveID) {
    //     return;
    //   }
    //   let data = {
    //     cisCode: this.$store.state.user.userInfo.customer.cisCode,
    //     orgCode: this.receiveID
    //   };
    //   isEnableOrNotBucha(data).then(res => {
    //     if(!res.data){
    //       return
    //     }
    //     const resData = JSON.parse(res.data);
    //     this.isEnableOrNot = resData.data;
    //   });
    // },

    getMetarial() {
      if (!this.receiveXSID ) {
        this.materialGroup = [];
        return;
      }
      findMaterialByStore({
        storeCode: this.receiveXSID
        // orgId: this.receiveID
      }).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.materialGroup = data.materialGroup;
      });
    },
    getCustromType() {
      // 17451是渠道采购，17452 自营或者17453 海信采购 （可能同时存在）
      // 自营
      if (this.isFenxiaoOrder.indexOf("17452") !== -1 && this.isFenxiaoOrder.indexOf("17451") == -1 && this.isFenxiaoOrder.indexOf("17453") == -1) {
        return 5;
      }
      if (
        // 代理
        (this.isFenxiaoOrder.indexOf("17452") !== -1 ||
          this.isFenxiaoOrder.indexOf("17453") !== -1) &&
        this.isFenxiaoOrder.indexOf("17451") == -1
      ) {
        return 1;
      } else if (
        //分销
        this.isFenxiaoOrder.indexOf("17451") !== -1 &&
        this.isFenxiaoOrder.indexOf("17452") == -1 &&
        this.isFenxiaoOrder.indexOf("17453") == -1
      ) {
        return 2;
      } else if (
        // 分销直营 不显示
        //共存 包括分销或者不包括直营 并且是海信 return 3 显示按钮
        (this.isFenxiaoOrder.indexOf("17451") !== -1 || this.isFenxiaoOrder.indexOf("17452") == -1) && this.isFenxiaoOrder.indexOf("17453") !== -1
      ) {
        return 3;
      } else if (this.isFenxiaoOrder.indexOf("17451") !== -1 && this.isFenxiaoOrder.indexOf("17452") !== -1 && this.isFenxiaoOrder.indexOf("17453") == -1) {
        return 4;
      }


    },
    //搜索按钮事件
    search() {
      if (!this.searchIpt || this.searchIpt.length < 2) {
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000066"
        });
        this.$message.info(msg && msg.msgDesc);
        // this.$message.info("请输入至少两个字符进行搜索！");
        return;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
    //调接口获取数据
    getData() {
      this.tabelIsLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          model: this.searchIpt,
          colour: "",
          warehouseId: this.receiveFHID,
          invStatusId: "",
          isLock: "",
          dealerMaterialGroupFlag: "",
          materialGroupCode: "",
          materialGroupName: "",
          gicWarehouseType: "",
          invStatusType: "",
          invType: "",
          bigQtyMin: 0,
          orgId: this.receiveID
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize
        }
      };
      getProductList(data).then(res => {
        this.tabelIsLoading = false;
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data.forEach(it => {
          it.borderedQty = 1;
          it.invStatus = it.invStatusId;
          it.bigUom = it.uom;
          it.uid = this.genID(10);
        });
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = data.page.totalCount;
        this.dataCache = data.data; //缓存列表数据，前端分页
        this.dataList = data.data.slice(start, start + this.pager.pageSize);

      });
      // }
    },
    //生成不重复id/后端返回的列表没有唯一键
    genID(length) {
      return Number(
        Math.random()
          .toString()
          .substr(3, length) + Date.now()
      ).toString(36);
    },
    editRow(item) {
      this.rowSelectionType = "radio";
      this.searchIpt = item.model;
      this.selRow = item;
      this.isVisible = true;
      this.getData();
    },
    async addProduct(productList) {
      this.FWlist = [];
      this.fwlbId = [];
      // 发货仓库为统舱 并且配送方式不等于直配到工地
      if (productList[0].gicWarehouseType == "20" && this.trans !== "07") {
        this.FWlist = [];
        this.fwlbId = [];
        // 根据销售组织和四级地址获取服务列表数据
        let data = {
          orgCode: productList[0].orgCode,
          warehouseCode: productList[0].gicWarehouse,
          provinceCode: this.proObj.proviceCode,
          cityCode: this.proObj.cityCode,
          countyCode: this.proObj.townCode,
          townCode: this.proObj.zCode
        };
        await getSeverList(data).then(res => {
          // 获取服务列表数据
          let data;
          if (typeof res.data == "string") {
            data = JSON.parse(res.data);
          } else {
            data = res.data;
          }
          if(data.code == '0') {
            this.FWlist = data.data;
          } else {
            this.FWlist = []
          }

          // 默认选中
          if (this.FWlist && this.FWlist.length > 0) {
            for (const item of this.FWlist) {
              if (item.isDefault == 1 && item.isSupport == 1) {
                this.fwlbId.push(item.serviceCode);
              }
              ;
            }
            ;
          }
        }).catch(res => {
        });
      }
      if(this.proIndex !== 'null'){
        if(productList[0].zzprdmodel == this.productListOld[this.proIndex].zzprdmodel && this.productListOld[this.proIndex].couponAmount>0){
          productList[0].isShowBt = true
          productList[0].bt = this.productListOld[this.proIndex].couponAmount?this.productListOld[this.proIndex].couponAmount:0
          // 优惠券ID、优惠券名称、优惠券金额、回收单号、缺少品类ID
          productList[0].couponId = this.productListOld[this.proIndex].couponId?this.productListOld[this.proIndex].couponId:''
          productList[0].couponName = this.productListOld[this.proIndex].couponName?this.productListOld[this.proIndex].couponName:''
          productList[0].couponAmount = this.productListOld[this.proIndex].couponAmount?this.productListOld[this.proIndex].couponAmount:''
          productList[0].recycleOrderNum = this.productListOld[this.proIndex].recycleOrderNum?this.productListOld[this.proIndex].recycleOrderNum:''
          this.$forceUpdate()
        } else {
          productList[0].isShowBt = false
          this.$forceUpdate()
        }
      }
       await checkRetailL({id:productList[0].productCode}).then(res => {
        if(res.data.code == 0){
          const {type, money, bei, min, max,haveRetailL } = res.data;// 0不限制  1限制
          productList = productList.filter(item => {
            item.FWlists = this.FWlist;
            item.fwlbId = this.fwlbId;
            if (item.productCode && item.productName) {
              return true;
            } else {
              // this.$message.warning("存在信息不全的产品，已去除！");
              const msg = this.$configTotal({
                route: this.$route.fullPath,
                id: "14180000067"
              });
              this.$message.warning(msg && msg.msgDesc);
              return false;
            }
          });
          // 如果是点击出库选择产品 走下面逻辑
          if(this.searchStr !== 'null'){
            // 工程机禁止录零售
            if(productList[0].productLabel.indexOf('14182273110') > -1 && this.XSLX == 'retail') {
              this.$message.info(`${productList[0].model}为工程机，不能录入零售订单，请选择销售类型工程`)
            }
            let invMap = {};
            // 所有的 组织 仓库 产品
            let uniqueInvKeyArr = [];
            let nowProdutct = productList[0]
            for(let i=0; i< this.productList.length; i++) {
              let product  = this.productList[i];
              uniqueInvKeyArr.push(product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode);
              let key = product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode + "_" + product.invStatusId +
                "_" + product.invStatusType;
              invMap[key] = product;
            }

            let uniqueInvKey = nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode;
            let invMapKey = uniqueInvKey + "_" + nowProdutct.invStatusId + "_" + nowProdutct.invStatusType;
            let invProduct = invMap[invMapKey];
            // 先判断行数据中是否有相同产品质量等级和补差类型  一致的 有的话 就提示  不替换当前行 没有的话就替换
            if(uniqueInvKeyArr.indexOf(uniqueInvKey) > -1) {
              //不能重复
              this.$message.warning("相同产品质量等级和补差类型必须保持一致，请重新选择!");
              return
            } else {
              // productList[0].bprice = productList[0].retailPrice;
              // productList[0].bprice1 = productList[0].retailPrice;
              productList[0].type = type;
              productList[0].bprice = money;
              productList[0].bprice1 = money;
              productList[0].bei = bei;
              productList[0].min = min;
              productList[0].max = max;
              productList[0].haveRetailL = haveRetailL;
              productList[0].checked = true
              this.$set(this.productList, this.proIndex, productList[0]);
              this.$forceUpdate()
              return
            }


          }
          if (this.rowSelectionType == "checkbox") {
            // 工程机禁止录零售
            if(productList[0].productLabel.indexOf('14182273110') > -1 && this.XSLX == 'retail') {
              this.$message.info(`${productList[0].model}为工程机，不能录入零售订单，请选择销售类型工程`)
            }
            let invMap = {};
            // 所有的 组织 仓库 产品
            let uniqueInvKeyArr = [];
            let nowProdutct = productList[0]
            // 如果数组大于0
            if(this.productList.length > 0) {
              for(let i=0; i< this.productList.length; i++) {
                let product  = this.productList[i];
                uniqueInvKeyArr.push(product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode);
                let key = product.orgCode + "_" + product.gicWarehouse + "_" + product.productCode + "_" + product.invStatusId +
                  "_" + product.invStatusType;
                invMap[key] = product;
              }

              let uniqueInvKey = nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode;
              let invMapKey = uniqueInvKey + "_" + nowProdutct.invStatusId + "_" + nowProdutct.invStatusType;
              let invProduct = invMap[invMapKey];
              // 如果组织型号补差类型仓库质量等级都一样 原来有的数据上数量+1
              if (invProduct) {
                nowProdutct.bprice1 = nowProdutct.retailPrice;
                invProduct.borderedQty += 1;
                this.$message.info("添加成功，请关闭弹窗或继续添加!");
                this.resetTrans()
                return
              } else {
                if(uniqueInvKeyArr.indexOf(uniqueInvKey) > -1) {
                  //不能重复
                  this.$message.warning("相同产品质量等级和补差类型必须保持一致，请重新选择!");
                  return
                } else {
                  nowProdutct.checked = true;
                  nowProdutct.itemId = "";
                  // nowProdutct.bprice = nowProdutct.retailPrice;
                  // nowProdutct.bprice1 = nowProdutct.retailPrice;
                  nowProdutct.type = type;
                  nowProdutct.bprice = money;
                  nowProdutct.bprice1 = money;
                  nowProdutct.bei = bei;
                  nowProdutct.min = min;
                  nowProdutct.max = max;
                  nowProdutct.haveRetailL = haveRetailL;
                  this.productList.push(nowProdutct);
                  this.$message.info("添加成功，请关闭弹窗或继续添加!");
                  this.resetTrans()
                  return
                }
              }
            } else{
              uniqueInvKeyArr.push(nowProdutct.orgCode + "_" + nowProdutct.gicWarehouse + "_" + nowProdutct.productCode);
              let key = nowProdutct.orgCode + "_" +nowProdutct.gicWarehouse + "_" + nowProdutct.productCode + "_" +
                nowProdutct.invStatusId + "_" + productList[0].invStatusType;
              invMap[key] = nowProdutct;
              nowProdutct.checked = true;
              nowProdutct.itemId = "";
              // nowProdutct.bprice = nowProdutct.retailPrice;
              // nowProdutct.bprice1 = nowProdutct.retailPrice;
              nowProdutct.type = type;
              nowProdutct.bprice = money;
              nowProdutct.bprice1 = money;
              nowProdutct.bei = bei;
              nowProdutct.min = min;
              nowProdutct.max = max;
              nowProdutct.haveRetailL = haveRetailL;
              this.productList.push(nowProdutct)
              this.$message.info("添加成功，请关闭弹窗或继续添加!");
              this.resetTrans()
              return
            }

          } else {
            if (productList.length > 0) {
              let pro = productList[0];
              const index = this.productList.findIndex(
                it => it.productCode == this.selRow.productCode
              );
              pro = {
                ...pro,
                checked: true,
                itemId: "",
                bprice: this.productList[index].bprice
              };
              this.$set(this.productList, index, pro);
            }
          }
        }else{
          if(res.data && res.data.msg){
            this.$message.warning(res.data.msg);
          } else {
            this.$message.warning('请联系产品公司总部维护零售价L');
          }
        }

      }).catch(error => {
        this.$message.warning('零售价L校验失败')
      });
      this.getFxGetDeliveryMethod()
    },
    getywy() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "ywy"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.ywy = Object.keys(obj.data[0])[0];
      });
    },
    // 开票方
    getkpf() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "kpf"
      };
      findBaseData(data).then(res => {
        var obj = JSON.parse(res.data);
        this.kpf = Object.keys(obj.data[0])[0];
      });
    },
    // 销售组织list
    getOrg() {
      let data = {
        customerCisCode: this.$store.state.user.userInfo.customer.cisCode
      };
      getOrgList(data).then(res => {
        var realDate = JSON.parse(res.data);

        // 销售组织
        var XSZZ = [];
        for (let item of realDate.data) {
          for (let key in item) {
            XSZZ.push({
              key,
              value: item[key]
            });
          }
        }
        if (XSZZ.length > 0) {

          this.receive = XSZZ[0].value;
          this.receiveID = XSZZ[0].key;
          this.orderDis = XSZZ;
          this.xszhId = XSZZ[0].value;

        }
      });

    },
    // 零售订单基础信息
    basicMessage() {
      let data = {
        account: this.$store.state.user.userInfo.customer.cisCode,
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        orgId: ""
      };
      this.pageLoadFlag = true;
      getBasicMessage(data).then(res => {
        var realDate = JSON.parse(res.data);
        this.realObj = realDate;
        // 零售客户
        // 销售门店
        var XSMDS = [];
        for (let key in realDate.baseInfo.storeInfo) {
          XSMDS.push({
            key,
            value: realDate.baseInfo.storeInfo[key].name,
            isSpeclalShop:realDate.baseInfo.storeInfo[key].isSpeclalShop,
            address:realDate.baseInfo.storeInfo[key].address,
            provinceCode:realDate.baseInfo.storeInfo[key].provinceCode,
            cityCode:realDate.baseInfo.storeInfo[key].cityCode,
            countyCode:realDate.baseInfo.storeInfo[key].countyCode,
            townCode:realDate.baseInfo.storeInfo[key].townCode,
          });
        }
        this.XSMD = XSMDS;
        //   获取详情数据
        this.getDetails()
        // 收货地址
        // 省
        var adres = realDate.baseInfo.address;
        let pro = Object.keys(adres.province)[0];
        let citykey = Object.keys(adres.city)[0];
        let countrykey = Object.keys(adres.country)[0];
        let townkey = Object.keys(adres.town)[0];
        this.proObj.proviceCode = pro;
        this.proObj.cityCode = citykey;
        this.proObj.townCode = countrykey;
        this.proObj.zCode = townkey;
        this.receivehisSH =
          adres.province[pro] +
          adres.city[citykey] +
          adres.country[countrykey] +
          adres.town[townkey];
        this.sheng = adres.province[pro];
        this.checkCity = adres.city[citykey];
        this.checkTownCode = adres.country[countrykey];
        this.checkXZ = adres.town[townkey];
        this.pageLoadFlag = false;
      }).catch(res => {
        this.pageLoadFlag = false;
      });
    },

    getWarehouseList() {
      // 根据销售组织获取仓库类型
      //发货地址改动接口 -》 根据仓库类型（20共享仓，70原仓）判断是否选择服务列表-》20统仓 选
      this.pageLoadFlag = true;
      getWarehouseList({ orgId: this.receiveID }).then(res => {
        this.pageLoadFlag = false;
        let data_ = JSON.parse(res.data);
        // 发货仓库
        let FHCK = [];
        for (let item of data_.data) {
          FHCK.push({
            key: item.cId,
            value: item.name,
            type: item.type
          });
        }
        if (FHCK.length > 0) {
          this.receiveFHS = FHCK[0].value;
          this.fhckId = FHCK[0].value;
          this.receiveFHID = FHCK[0].key;
          this.receiveFH = FHCK;
          //仓库类型 -》 20统舱，70原仓
          this.fhckType = FHCK[0].type;
          //根据仓库的默认第一项判断获取服务列表 及是否展示
          if (this.fhckType == 20) {
            this.getSeverInfo();
          }
        } else {
          this.receiveFHS = "";
          this.fhckId = "";
          this.receiveFHID = "";
          this.receiveFH = [];
          this.fhckType = "";
          this.fwlbId = [];
        }

      }).catch(res => {
        console.log(res);
        this.pageLoadFlag = false;
      });
    },
    onChange(selKeys, selRows) {},
    // 销售门店
    receiveAddressXS() {
      this.receiveAddXS = !this.receiveAddXS;
    },
    // 发货仓库
    receiveAddressFH() {
      this.receiveAddFH = !this.receiveAddFH;
    },
    handleChangeAddressFH(value, e) {
      this.receiveFHS = value;
      // 发货仓库 选中 id
      this.receiveFHID = e.key;
      this.receiveAddFH = !this.receiveAddFH;
      this.productList = [];
      for (const item of this.receiveFH) {
        if (item.key == e.key) {
          this.fhckType = item.type;
          // 如果仓库类型为同仓并且 配送方式为工程  不显示服务列表 并且 传值为空
          if (this.fhckType == "20" && this.trans == "07") {
            this.FWlist = [];
            this.fwlbId = [];
            return;
          } else if (this.fhckType == "20" && this.trans !== "07") {
            this.getSeverInfo();
            return;
          } else {
            this.fwlbId = [];
          }
          return;
        }
      }
    },
    handleChangeAddressXS(value, e) {
      let obj = this.XSMD.find((i) => {
        return i.key === e.key;//筛选出匹配数据
      });
      this.mdlx = obj.isSpeclalShop
      this.shengCode = obj.provinceCode
      this.checkCity = obj.cityCode
      this.checkTownCode = obj.countyCode
      this.checkXZ = obj.townCode
      this.proObj.proviceCode = obj.provinceCode
      this.proObj.cityCode = obj.cityCode
      this.proObj.townCode = obj.countyCode
      this.proObj.zCode = obj.townCode
      // this.userDetailAddress = obj.address
      // this.addressHx = [this.shengCode,this.checkCity,this.checkTownCode,this.checkXZ]
      this.receiveXS = value;
      // 收货地址 选中 id
      this.receiveXSID = e.key;
      this.getMetarial();
      this.receiveAddXS = !this.receiveAddXS;
    },
    // dms配送方式
    getPS() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account
      };
      getDeliveryMode(data).then(res => {
        var obj = JSON.parse(res.data);
        // // 配送方式
        // this.deliveryOptions = res.data.cartOrder.deliveryList;
        // // 默认配送方式id
        // this.trans = res.data.cartOrder.deliveryList[0].id
        var arr = [];
        for (let item of obj.data) {
          for (let key in item) {
            arr.push({
              id: key,
              propertyName: item[key]
            });
          }
        }
        this.deliveryOptions = arr;
        this.trans = arr[0].id;


      });
    },
    // cis查询配送方式接口
    getDelivery() {
      let data = {
        type: this.XSLX,
        sortOrder: "asc"
      };
      getDeliveryType(data).then(res => {
        if (res.data.list && res.data.list.length > 0) {
          this.deliveryOptions = res.data.list;
          this.trans = this.deliveryOptions[0].code;
        }
      });
    },
    carList() {
      let data = {};
      getProvice(data).then(res => {

        // this.choseCity(this.sheng||res.data[0].provinceCode);
        this.provices = res.data;
        // this.proObj.proviceCode = res.data[0].provinceCode;
      });
    },
    forcusS() {
      if (this.productList.length > 0) {
        this.$message.warning("请先清空商品，再重新选择地址");
        return;
      }
    },
    // 省 选择
    handleProvinceChange(value, e) {
      this.proObj.proviceCode = e.key;
      this.sheng = value;
      this.choseCity(value);
      this.proObj.cityCode = "";
      this.checkCity = "";
      this.proObj.zCode = "";
      this.checkTownCode = "";
      this.proObj.townCode = "";
      this.checkXZ = "";

      // 获取服务列表
      this.getSeverInfo();
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    // 获取城市
    choseCity(code) {
      let data = {
        proviceCode: code
      };
      cityChange(data)
        .then(res => {
          this.getCity = res.data;
          // this.checkCity = res.data[0].cityName;
          //获取城镇区
          this.getTowns(this.checkCity || res.data[0].cityCode);
          // this.proObj.cityCode = res.data[0].cityCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇区
    getTowns(code) {
      let data = {
        cityCode: code
      };
      townChange(data)
        .then(res => {
          this.getTown = res.data;
          // this.checkTownCode = res.data[0].districtName
          // this.proObj.townCode = res.data[0].districtCode;
          let storage = localStorage.getItem(this.$store.state.user.userInfo.account.account);
          let saleInfo1= JSON.parse(storage);
          if (saleInfo1.townCode) {
            this.getqu(saleInfo1.townCode)
          } else {
            this.getqu(this.proObj.townCode || res.data[0].districtCode)
          }

        })
        .catch(err => {
          console.log(err);
        });
    },
    // 获取城镇下一级 town
    getqu(code) {
      let data = {
        districtCode: code
      };
      getQu(data)
        .then(res => {
          // this.checkXZ = res.data[0].townName;
          this.qu = res.data;
          // this.proObj.zCode = res.data[0].townCode;
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 城市选择
    handleCityChange(e) {
      this.proObj.cityCode = e;
      this.proObj.zCode = "";
      this.checkTownCode = "";
      this.proObj.townCode = "";
      this.checkXZ = "";
      this.getTowns(e);
      // 获取服务列表
      this.getSeverInfo();
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    // 城镇选择
    handleTownChange(e) {
      this.proObj.townCode = e;
      this.getqu(e);
      this.proObj.zCode = "";
      this.checkXZ = "";
      // 获取服务列表
      this.getSeverInfo();
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    // 城镇下一级 选择
    handleZhenChange(e) {
      this.proObj.zCode = e;
      // this.checkXZ =
      this.getSeverInfo();
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      this.visible = false;
    },
    getIsSxProduct(){
      let ids = this.productList.map(it=>it.productCode)
      if(ids<=0) {
        this.sXFlag = null
        this.sXMessage = ''
        return
      }
      let data = {
        provinceId:this.proObj.proviceCode,
        cityId:this.proObj.cityCode,
        productIdList:ids,
      }
      //
      isSxProduct(data).then(res=>{
        this.sXFlag = res.data.data.configAddress
        this.sXMessage = res.data.data.message
        if(res.data.code== 0 ) {
          this.trans = '08'
          this.transDefault = this.deliveryOptions.findIndex(el => el.code == '08')>-1?this.deliveryOptions.findIndex(el=>el.code == '08'):''
          this.haveDeliveryFee = this.deliveryOptions[this.transDefault].haveDeliveryFee == 1? true : false
          if(!this.sXFlag &&  this.trans=='08'){
            this.isVisible = false
            this.visibleAAA = true
            this.showInfo = this.sXMessage
          }
        }else {
          this.isVisible = false
          this.visibleAAA = true
          this.showInfo = this.sXMessage
        }
      })
    },

    // 查询服务费
    getFWF(){
      if( !this.proObj.zCode ){
        this.$message.warning('请选择省市区县')
        return
      }
      this.startTime = ''
      this.startShowTime = ''
      let orderServiceFeeItemDtoList = []
      this.productList.forEach(item=>{
        orderServiceFeeItemDtoList.push({
          "productId": item.productCode,		//产品id
          "warehouseInfoId": item.gicWarehouse || '',			//仓库werks-lgort，例如2600-1532，dms代理商下单必传，其他情况传空
          "qty": item.borderedQty,
          zzprdmodel:item.productName,
          orgId:item.orgCode,
        })
      })
      let data = {
        "townCode":  this.proObj.zCode,
        "countyCode": this.proObj.townCode,
        "cityCode":  this.proObj.cityCode,
        "provinceCode":  this.proObj.proviceCode ,
        "warehouseInfoId": '',			//发货仓库id，信天翁代理商下单必填，信天翁分销商下单或者dms下单必须传空
        "shopCode":  '',			//门店idthis.receiveXSID
        "documentSource":"5",			//dms下单传5，其他随便传个不是5的数就行
        "orderServiceFeeItemDtoList": orderServiceFeeItemDtoList,
        trans:this.trans
      }
      findMoneyBuyBegin(data).then(res => {
        if(res.data && res.data.data && res.data.data.timelinessDate){
          this.startTime =  res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
          this.startShowTime = res.data.data.timelinessDate?res.data.data.timelinessDate.replace(/-/g,"/"):''
        } else{
          //  如果接口没返回数据 就默认当前日期加3天
          this.startTime =  this.$util.getDateSpecify(3)
          this.startShowTime = this.$util.getDateSpecify(3)
        }
        if(res.data.code == 0){
          this.serviceCharge = res.data.data.totalAmount
          this.orderServiceFeeItemDtoList = res.data.data.orderServiceFeeItemDtoList
          this.orderServiceFeeItemDtoList.forEach((itemFirst,indexFirst)=>{
            this.productList.forEach((item,index)=>{
              if( itemFirst.productId == item.productCode ){
                this.$nextTick(()=> {
                  this.$set(item,'serviceMoney',itemFirst.serviceFee)
                })
              }
            })
          })
        }
        // else  {
        //   this.serviceCharge = 0
        //   this.productList.forEach((item,index)=>{
        //     item.serviceMoney = 0.00
        //   })
        // }
        if(res.data.code == 1 ){
          this.serviceCharge = 0
          this.productList.forEach((item,index)=>{
            this.$nextTick(()=> {
              this.$set(item,'serviceMoney','0.00')
            })
          })
        }
      }).catch(error=>{
        //  如果接口没返回数据 就默认当前日期加3天
        this.startTime =  this.$util.getDateSpecify(3)
        this.startShowTime = this.$util.getDateSpecify(3)
      })
    },
    // 获取配送方式
    getFxGetDeliveryMethod(){
      // 如果配送方式和勾选数据不符合 就提示
      let optionKey = this.deliveryOptions.map(it=>{
        return it.code
      })
      if( !optionKey.includes(this.trans) ){
        this.trans = ''
      }
      const items = [], orgMatkl = []
      if(this.productList && this.productList.length > 0) {
        this.productList.forEach(el => {
          if(orgMatkl.length == 0 || !orgMatkl.includes(`${el.orgCode}-${el.materialGroupCode}`)) {
            orgMatkl.push(`${el.orgCode}-${el.materialGroupCode}`)
          }
        })
        orgMatkl.forEach((it, index) => {
          items.push({
            orgId: it.split('-')[0],
            matklCode: it.split('-')[1],
            productIds: []
          })
          this.productList.forEach(el => {
            if(it == `${el.orgCode}-${el.materialGroupCode}`) {
              items[index].productIds.push({
                id: el.productCode,
                warehouseName: el.warehouseName,
                isShareWarehouse: el.gicWarehouseType == '20' ? true : false // 是否共享仓
              })
            }
          })
        })
      }
      let data = {
        orderType: this.XSLX == 'retail' ? 3 : 4, // 订单类型-3：零售录入（零售），4：零售录入（工程）
        // serviceCode: 'cisFxZsyhGetDeliveryMethod',
        code:this.$store.state.user.userInfo.customer.cisCode,
        fxCustCisCode: this.$store.state.user.userInfo.customer.cisCode,
        dlCustCisCode:this.$store.state.user.userInfo.customer.cisCode,
        items: items,
      }
      fxZsyhGetDeliveryMethod(data).then(res=>{
        if(res.data.code == 0) {
          this.deliveryOptionsOld = res.data.data.items
          this.deliveryOptions = res.data.data.items
          this.isShowLogisticsIcon = res.data.data.items.some(el => ['04', '08'].includes(el.code))

          // 获取接口中check == true 默认选中的下标
          this.transDefault = this.deliveryOptions.findIndex(el => el.check == true)>-1?this.deliveryOptions.findIndex(el => el.check == true):''
          // 赋值配送方式
          this.trans = this.deliveryOptions[this.transDefault].code
          // 是否展示服务费 根据配送方式中字段判断 // 0否，1是
          this.haveDeliveryFee = this.deliveryOptions[this.transDefault].haveDeliveryFee == 1? true : false
          if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
            this.getFWF()
          }
          this.resetTrans()
          this.$forceUpdate()
        }
      })
    },
    onGiveType(e) {
      // this.trans = this.deliveryOptions[e.target.value].code;
      this.trans = e.target.value
      let obj = this.deliveryOptions.find((i) => {
        return i.code === this.trans;//筛选出匹配数据
      });
      this.haveDeliveryFee = obj&&obj.haveDeliveryFee == 1? true : false
      this.startTime =  ''
      this.startShowTime = ''
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      // 如果仓库类型为同仓并且 配送方式为工程  不显示服务列表 并且 传值为空
      if (this.fhckType == "20" && this.trans == "07") {

        this.FWlist = [];
        this.fwlbId = [];
        return;

      } else if (this.fhckType == "20" && this.trans !== "07") {
        this.getSeverInfo();
        return;
      }
    },
    // 分销商 分销地址切换获取手机号
    showAnotheer() {
      getPhone({
        sendToId: this.distributionAdd.DISCODE
      }).then(() => {
      });
    },
    onSubmit() {
      //form
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    // 点击选择开票户头
    choseAccount() {
      // axios请求后台开票户头
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 用户级联选择改变
    cityChange(value) {
    },
    // 判断当前账户是否签约贷款合同
    getisKQ(){
      let data = {
        serviceCode:'queryDeliveryAmountFind',
        mdmCode:this.$store.state.user.userInfo.customer.customerCode
      }
      getDeliveryAmountFind(data).then(res=>{
        if(res.data.success == 'true'){
          this.showTIP = res.data.msg=='true'?true:false
          this.$forceUpdate()
        }else {
          this.showTIP = false
        }
      })
    },
    gotoCK(){
      this.$router.push({
        path:"/stockCenter/myStock"
      });
    },
    dataChange(e){
        console.log('e',e)
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      this.resetTrans()
    },
    // 销售类型
    // 销售类型
    onGiveTypeLX(e) {
      this.trans = ''
      this.deliveryOptions=[]
      // this.XSLX = this.saleOptions[e.target.value].id;
      this.XSLX = e.target.value
      this.getFxGetDeliveryMethod();
      if((this.trans == '04' || this.trans == '08') && this.proObj.zCode){
        this.getFWF()
      }
      const saleInfo = {
        xsmdId: this.xsmdId,
        receiveXSID:this.receiveXSID,
        sheng:this.sheng,
        checkCity:this.checkCity,
        checkTownCode:this.checkTownCode,
        checkXZ:this.checkXZ,
        proviceCode:this.proObj.proviceCode,
        cityCode:this.proObj.cityCode,
        townCode:this.proObj.townCode,
        zCode:this.proObj.zCode,
        XSLX:this.XSLX
      };
      localStorage.setItem(this.$store.state.user.userInfo.account.account, JSON.stringify(saleInfo));

    },
    // 是否依旧换新
    oldNewChange(e) {
      this.isOldNew = e.target.value;
    },
    // 完成收旧
    completeOldChange(e) {
      this.isCompleteOld = e.target.value;
    },
    // 直送用户/送装一体地区校验
    async deliveryAddressCheck() {
      const orgparamsList = this.productList.map(el => {
        return {
          orgId: el.orgCode,
          matklId: el.materialGroupCode
        }
      })
      let data = {
        "serviceCode":"cisDeliveryAddressCheck",
        "provinceCode":this.proObj.proviceCode,
        "cityCode":this.proObj.cityCode,
        "districtCode":this.proObj.townCode,
        "townCode":this.proObj.zCode,
        "custId":this.$store.state.user.userInfo.customer.id,
        orgparamsList:orgparamsList,
        orderType:1,
        deliveryMethod:this.trans,
        haveDeliveryFee:this.haveDeliveryFee?1:0,// 是否展示服务费
      }
      // ctsApi(data).then(res=>{
      //   if(res.data.code == 0) {
      //   } else {
      //     this.$message.warning(res.data.msg)
      //   }
      //
      // })
      return ctsApi(data)
    },
    // 是否是璀璨试点区域
    isBrightMethods(){
      return isBrightPilotRegion({regionTownCode:this.proObj.zCode})
    },
    // dms 订单提交
    async submitOrder() {
      // 如果不是商显 弹出提示
      if(!this.sXFlag && this.trans == '08' && this.allTC && !this.isNoSxProduct) {
        this.isLoading = false
        this.visibleAAA = true
        this.showInfo = this.sXMessage
        return
      }
      if(this.mdlx != 1){
        const index1 = this.deliveryOptions.findIndex(el => el.code == '04')
        if(['04'].includes(this.trans) && this.deliveryOptions[index1].msg) {
          this.$message.warning(this.deliveryOptions[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.deliveryOptions.findIndex(el => el.code == '08')
        if(['08'].includes(this.trans) && this.deliveryOptions[index2].msg) {
          this.$message.warning(this.deliveryOptions[index2].msg)
          return false
        }
        const index3 = this.deliveryOptions.findIndex(el => el.code == '09')
        if(['09'].includes(this.trans) && this.deliveryOptions[index3].msg) {
          this.$message.warning(this.deliveryOptions[index3].msg)
          return false
        }
        //
        const index4 = this.deliveryOptions.findIndex(el => el.code == '10')
        if(['10'].includes(this.trans) && this.deliveryOptions[index4].msg) {
          this.$message.warning(this.deliveryOptions[index4].msg)
          return false
        }

      }
      if(['04', '08'].includes(this.trans) && this.mdlx== 1) {
        const index1 = this.deliveryOptions.findIndex(el => el.code == '04')
        if(['04'].includes(this.trans) && this.deliveryOptions[index1].msg) {
          this.$message.warning(this.deliveryOptions[index1].msg)
          return false
        }
        // 判断下单产品是否支持送装一体
        const index2 = this.deliveryOptions.findIndex(el => el.code == '08')
        if(['08'].includes(this.trans) && this.deliveryOptions[index2].msg) {
          this.$message.warning(this.deliveryOptions[index2].msg)
          return false
        }
        const index3 = this.deliveryOptions.findIndex(el => el.code == '09')
        if(['09'].includes(this.trans) && this.deliveryOptions[index3].msg) {
          this.$message.warning(this.deliveryOptions[index3].msg)
          return false
        }
        //
        const index4 = this.deliveryOptions.findIndex(el => el.code == '10')
        if(['10'].includes(this.trans) && this.deliveryOptions[index4].msg) {
          this.$message.warning(this.deliveryOptions[index4].msg)
          return false
        }

        const res =  await this.deliveryAddressCheck()
        if(res.data.code == 1) {
          this.$message.warning(res.data.msg)
          return false
        }
        if(this.userDetailAddress == '' ){
          this.$message.warning('请填写详细地址');
          this.isLoading = false
          return;
        }
        if(this.TipsIds.length == 0) {
          this.$message.warning('请选择用户标签')
          return false
        }
        // if (this.proObj.zCode) {
        //   const response = await this.isBrightMethods()
        //   this.isBright = response.data.code == 0 ?response.data.data:false
        // }
      }
      if(['04', '08',].includes(this.trans) ) {
        // 获取产品是璀璨的长度
        let brightRow = this.productList.filter(it=>it.productLabel&&it.productLabel.indexOf('15691143849')>-1)
        // 产品有璀璨 并且 有省市区县 并且是直送用户或者送装一体
        if (this.proObj.townCode&&brightRow.length>0) {
          const response = await this.isBrightMethods()
          this.isBright = response.data.code == 0 ?response.data.data:false
        }
      } else {
        this.isBright = false
      }
      // 工程机禁止录零售
      const isExitList = this.productList.filter(el => el.productLabel.indexOf('14182273110') > -1)
      if(isExitList.length > 0 && this.XSLX == 'retail') {
        const modelList = isExitList.map(el => el.model)
        this.$message.warning(`${modelList.join('、')}为工程机，不能录入零售订单，请选择销售类型工程`)
        return false
      }
      if(this.openCloseBtn){
        if (!this.cherkStatus) {
          this.$message.warning("请确认质物明细！");
          return;
        }
      }

      if (this.isOldNew == 1) {
        if (!this.oldMachCategory) {
          this.$message.warning("请选择旧机品类");
          this.isLoading = false
          return;
        }
        if (!this.oldMachTreatWay&&this.recoveryTxt!='2') {
          this.$message.warning("请选择旧机处理途径");
          this.isLoading = false
          return;
        }
      }
      // return
      if (!this.business.code) {
        // this.$message.warning("请输入零售客户手机号");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000077"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (this.business.code.trim().length !== 11) {
        this.$message.warning("手机号不正确，请重新填写");
        this.isLoading = false
        return;
      }
      if (!this.business.name) {
        // this.$message.warning("请输入零售客户姓名");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000078"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.receiveXSID) {
        // this.$message.warning("请选择销售门店");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000079"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.business.name) {
        // this.$message.warning("请输入零售客户姓名");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000078"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }


      if (!this.userDetailAddress) {
        // this.$message.warning("请输入详细地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000081"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.trans) {
        // this.$message.warning("请选择配送方式");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000071"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (
        !(
          this.proObj.zCode &&
          this.proObj.proviceCode &&
          this.proObj.cityCode &&
          this.proObj.townCode
        )
      ) {
        // this.$message.warning("请选择直配地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000080"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.userDetailAddress) {
        // this.$message.warning("请输入详细地址");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000081"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if (!this.XSLX) {
        // this.$message.warning("请选择销售类型");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000073"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      if(this.mdlx==1&&this.TipsIds.length <= 0){
        this.$message.warning('用户标签不能为空，请选择')
        return
      }
      if(this.mdlx==1&&!this.sexId){
        this.$message.warning('请选择性别')
        return
      }
      const salesOrderItem = [];
      this.productList.forEach(item => {
        item.amount = 0
        // item.amount = new Number((item.bprice || 0) * (item.borderedQty || 0)).toFixed(2);
        item.amount = (item.bprice-(item.bt||0)) * item.borderedQty >= 0?(item.bprice-(item.bt||0)) * item.borderedQty:0
        if (item.checked) {
          salesOrderItem.push({
            productCode: item.productCode,
            materialCode: item.materialCode,
            model: item.model,
            invStatus: item.invStatusId,
            invStatusType: item.invStatusType,
            borderedQty: item.borderedQty,
            bprice: item.bprice,
            amount: item.amount,
            gicWarehouse: item.gicWarehouse,
            orgCode: item.orgCode,
            // zoneB2cService: item.fwlbId ? item.fwlbId.join(",") : ""
            zoneB2cService:item.gicWarehouseType == '20'?'0':'',
            couponId :item.couponId?item.couponId:'',
            couponName :item.couponName?item.couponName:'',
            couponAmount :item.couponAmount?item.couponAmount:'',
            recycleOrderNum :item.recycleOrderNum?item.recycleOrderNum:''
          });
        }
      });
      if (salesOrderItem.length < 1) {
        // this.$message.warning("请至少选择一个商品！");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000075"
        });
        this.$message.warning(msg && msg.msgDesc);
        this.isLoading = false
        return;
      }
      let TJmoney = 0
      let orgProductList = []
      for (let i = 0; i < this.productList.length; i++) {
        const item = this.productList[i];
        if (item.checked) {
          if (!item.orgCode) {
            this.$message.warning(`商品${item.zzprdmodel}未选择出库仓库，请您确认出库仓库后再操作出库！`);
            orgProductList.push(item.zzprdmodel)
            return
          }
          if (!item.bprice) {
            this.$message.warning(`请检查第${i + 1}个商品价格！`);
            this.isLoading = false
            return;
          }
          if (!item.borderedQty) {
            this.$message.warning(`请检查第${i + 1}个商品数量！`);
            this.isLoading = false
            return;
          }
          if(item.gicWarehouseType == '20') {
            TJmoney += (Number(item.borderedQty) * Number(item.bprice))
          }
        }
      }
      if(orgProductList.length > 0) {
        this.$message.warning(`商品${orgProductList.join(',')}未选择出库仓库，请您确认出库仓库后再操作出库！`);
        this.isLoading = false
        return;
      }
      this.SorceIds = null

      let salesOrder = {
        haveDeliveryFee:this.haveDeliveryFee?1:0,
        documentNum:this.documentNum,
        gender:this.mdlx==1?this.sexId:'', // 性别
        customerLabels:this.mdlx==1?this.TipsIds.join(','):'', // 用户标签
        sourceId:this.mdlx==1 ? "5007687" : '',
        retailType: this.XSLX,
        isOutbound: 1,
        // discountAmount: 0,
        provinceId: this.proObj.proviceCode,
        cityId: this.proObj.cityCode,
        townId: this.proObj.zCode,
        countryId: this.proObj.townCode,
        message: this.BZvalue,
        customerName: this.business.name,
        // 开票方id
        billFromId: this.kpf,
        // 业务员编码
        sellerCode: this.ywy,
        // 销售单明细，商品行信息
        salesOrderItem: salesOrderItem,
        edt: this.startTime,
        // edt: time2,
        phone: this.business.code,
        // warehouseId: this.receiveFHID,
        deliveryMode: this.trans,
        fullAddress: this.userDetailAddress,
        // 门店编码
        storeCode: this.receiveXSID,
        isGroup: "0",//是否套购暂不用但必传
        attachmentIds: this.ImgIds.join(","),
        isOldNew: this.isOldNew,
        isCompleteOld: this.isCompleteOld,
        oldMachCategory: this.oldMachCategory,
        oldMachCategoryName: this.oldMachCategoryName,
        oldMachTreatWay: this.oldMachTreatWay,
        oldMachTreatWayName: this.oldMachTreatWayName,
        // 回收方式
        recycleWay:this.recoveryTxt,
        // taskId:this.compassHouseTaskId,
        // taskName:this.compassHouseTaskName,
        brightFlag:this.isBright,
        sXFlag:(this.trans=='08'&&this.allTC&&this.sXFlag&&!this.isNoSxProduct)?this.sXFlag:'',
      };
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        salesOrder: salesOrder,
        status: "submitted"
      };
      this.isLoading = true;
      await retailOrder(data)
        .then(res => {
          var obj = JSON.parse(res.data);
          if (obj.code == "1") {
            // this.$message.warning(obj.msg);
            this.visibleAAA = true
            this.showInfo = obj.msg
          } else if (obj.code == "0") {
            if(obj.deliveryOrderNum) {
              this.$router.push({
                path: "/serviceFeeModule/pay",
                query: {
                  id:obj.deliveryOrderNum,
                  fwfOrder:obj.deliveryOrderNum,
                  batch:0,
                  b2bOrderCode: obj.msg,
                  type: "retail",
                  orgName: "订单号",
                  data:obj.data,
                  channel: 2
                }
              });
              return
            }
            this.$router.push({
              path: "/confirm/inputSuccess",
              query: {
                b2bOrderCode: obj.msg,
                type: "retail",
                orgName: "订单号",
                data:obj.data
              }
            });
          }
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // 订单类型切换
    orderChange(type) {
      this.$emit("changeType", type);
    },
    disabledDateS(current) {
      // let nowDate = moment().format("YYYY-MM-DD")
      // 目前这个值是写死的 后期需要对接口
      let three = moment(this.startShowTime)
      // return current < moment().startOf('days') || current > new Date(this.$util.getDate30())
      return current < moment(this.startShowTime) || current > three.add(29, 'd')
    },
    arrivalTimeFocus(){
      if(!this.startTime && this.haveDeliveryFee){
        this.$message.warning('请先填选相关信息')
        return
      }else {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(){
      if(this.startTime){
        this.endOpen = true;
        return
      }
    },
    // 时间更改
    handleTimeChange(e, defaultTime) {
      this.startTime = defaultTime;
      this.endOpen = false;
    },
    // 控制可选时间范围
    disabledDate(current) {
      return current && current < moment().endOf("day");
    },
    // 获取子组件选中商品
    sonCheckList(data) {
      let cheacked = this.sonChectItme.indexOf(data);
      if (this.sonChectItme.indexOf(data) == -1) {
        this.sonChectItme.push(data);
      } else {
        this.sonChectItme.splice(cheacked, 1);
      }

    },
    //   点击返回购物车
    goCar() {
      this.$router.push("/channelOrder/orderList?type=retail");
    },
    // 收货地址点击选择
    receiveAddress() {
      this.receiveAdd = !this.receiveAdd;
    },
    // 销售组织
    handleChangeAddress(value, e) {
      this.receive = value;
      // 收货地址 选中 id
      this.receiveID = e.key;
      // this.SAddressChange(e.key)
      this.receiveAdd = !this.receiveAdd;
      this.basicMessage(e.key);
      this.getWarehouseList();

    },
    // 收货地址
    hishandleChangeAddress(value, e) {
      this.test = value;
      this.distributionAdd.DISCODE = e.key;
      this.officeCode = e.key;
      this.hisenseOff = !this.hisenseOff;
      this.showAnotheer();
    },
    // 切换收货地址
    SAddressChange(id) {
      showAnother({
        id
      })
        .then(res => {
        })
        .catch(err => {
          console.log(err);
        });
    },
    // 分销商显示隐藏
    choseDistr() {
      this.distr = !this.distr;
    },
    // 切换分销商
    handleChangeDistr(value, e) {
      this.distrName = value;

      this.DISADDPARAMS.customerId = e.key;
      this.getDISADDList();
    },
    // 分销商切换获取分销商地址list
    getDISADDList() {
      let data = this.DISADDPARAMS;
      getDISAdd(data)
        .then(res => {

          this.choseDistribution = res.data;
          this.distributionAdd.DISCODE = res.data[0].id;
        })
        .catch(err => {
          console.log(err);
        });
    },
    distrAddShow() {
      this.distrAdd = !this.distrAdd;
    },
    // 选择经销商地址
    handleChangeDistrAdd(value, e) {
      this.distributionAdd.DISCODE = e.data.key;
      this.distributionAdd.DISADD = value;

      this.distrAddress = value;
      getPhone({
        sendToId: e.key
      }).then(res => {
        console.log(res);
      });
    },
    // 分销商地址切换获取联系人
    getPeople() {
      getPhone();
    },
    // 弹框
    openAdd(e,e1) {
      this.rowSelectionType = "checkbox";
      if(e !== null) {
        this.searchStr = e
        this.proIndex = e1
      }
      this.isVisible = true;
      if (!this.searchIpt || this.searchIpt.length < 2) {
        this.selRows = []; //切换页码清空勾选的行
        this.onlyShowChecked = false;
        this.pager.count = 0;
        this.dataCache = []; //缓存列表数据，前端分页
        this.dataList = [];
        return;
      }
      this.getData();
    },
    // 海信办事处
    hisenseOffice() {
      this.hisenseOff = !this.hisenseOff;
    },
    // 获取旧机品类
    getOldMachCategory() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "xsddjjpl"
      };
      findBaseData(data).then(res => {
        res = JSON.parse(res.data).data;
        for (let item of res) {
          let obj = {};
          let key = Object.keys(item)[0];
          obj.key = key;
          obj.value = item[key];
          this.oldMachCategorys.push(obj);
        }

      });
    },
    // 获取旧机处理途径
    getOldMachTreatWays() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        userAccount: this.$store.state.user.userInfo.account.account,
        type: "xsddjjcltj"
      };
      findBaseData(data).then(res => {
        res = JSON.parse(res.data).data;
        for (let item of res) {
          let obj = {};
          let key = Object.keys(item)[0];
          obj.key = key;
          obj.value = item[key];
          this.oldMachTreatWays.push(obj);
        }
      });
    },
    // 旧机品类修改
    oldMachCategoryChange(e) {
      for (let item of this.oldMachCategorys) {
        if (e == item.key) {
          this.oldMachCategoryName = item.value;
          return;
        }
      }
    },
    // 旧机处理途径修改
    oldMachTreatWayChange(e) {
      this.oldMachTreatWay = e;
      for (let item of this.oldMachTreatWays) {
        if (e == item.key) {
          this.oldMachTreatWayName = item.value;
          return;
        }
      }
    }
  }
};
