import dmshttp from "@/utils/dmsrequest";

// 销售组织列表
export function getDealerList(data) {
  return dmshttp({
    url: "getDealerList",
    data
  });
}
// 补差类型
export function isEnableOrNotBucha(data) {
  return dmshttp({
    url: "isEnableOrNot",
    data
  });
}

// 仓库列表
export function getWarehouseListApi(data) {
  return dmshttp({
    url: "getWarehouseList",
    data
  });
}

// 保存库存调整
export function saveOtherStockTrans(data) {
  return dmshttp({
    url: "saveOtherStockTrans",
    data
  });
}
